<template>
  <div>
    <commonPageBanner :url="backUrl" />
    <div class="detailBox">
      <h2 class="title">{{ detail.title }}</h2>
      <div class="date">发布时间： {{ detail.created_time | secondFormat("LL") }}</div>
      <div class="descBox content_area" v-html="detail.content"></div>
    </div>
    <div class="applyBtn" v-if="detail.has_apply_link && !status && detail.content_type == 1">
      <img :src="img" alt srcset />
      <span @click="apply">在线申请参加活动</span>
    </div>
    <el-dialog title="在线申请" :visible.sync="dialogVisible" width="744px">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="80px"
        class="form"
        :show-message="false"
      >
        <img :src="applyImg" alt srcset />
        <div class="forms">
          <el-form-item label="企业名称" required prop="company">
            <el-input v-model="form.company" placeholder="请输入企业名称"></el-input>
          </el-form-item>
          <el-form-item label="联系人" required prop="name">
            <el-input v-model="form.name" placeholder="请输入联系人"></el-input>
          </el-form-item>
          <el-form-item label="联系方式" required prop="concatMethed">
            <el-input v-model="form.concatMethed" placeholder="请输入联系方式"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button class="confirmBtn" type="primary" @click="submit('form')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import commonPageBanner from "~xif/components/layouts/pageBanner";
export default {
  components: { commonPageBanner },
  data() {
    return {
      desc: "<p>I believe I can fly</p>",
      img:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/apply.png",
      dialogVisible: false,
      conlseVisible: false,
      form: {
        company: "",
        name: "",
        concatMethed: "",
      },
      rules: {},
      detail: {},
      backUrl:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/Banner_tree.png",
      img1:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/active.png",
      img2:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/xiehui.png",
      img3:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/hangye.png",
      img4:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/zhengce.png",
      status: 0,
      userInfo: "",
    };
  },
  mounted() {
    this.getDetail();
    this.getStatus();
    this.userInfo = this.USER_INFO;
  },
  computed: {
    applyImg() {
      let src = "";
      if (this.detail.img_url) {
        src = this.detail.img_url;
      } else {
        if (this.detail.content_type == 1) {
          src = this.img1;
        }
        if (this.detail.content_type == 2) {
          src = this.img2;
        }
        if (this.detail.content_type == 3) {
          src = this.img3;
        }
        if (this.detail.content_type == 4) {
          src = this.img4;
        }
      }
      return src;
    },
  },
  methods: {
    apply() {
      if (!this.IS_LOGIN) {
        this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN", {
          waitTodo: () => {
            this.getStatus()
          }
        });
        return;
      } else {
        if (!this.USER_INFO.company_id) {
          this.$router.push({
            path:"/console/company/basic"
          }) 
          return;
        } else {
          this.dialogVisible = true;
        }
      }
    },
    toConsole() {
      this.$router.push({ path: "/console/company/basic" })
    },
    async getStatus() {
      let data = await this.$store.dispatch("API_index/nowStatus", {
        user_id: this.USER_INFO.id,
        source: this.PJSource,
        event_id: this.$route.query.id,
      });
      if (data.success) {
        if (JSON.stringify(data.data) != "{}") {
          this.status = 1;
        }
      }
    },
    submit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let data = await this.$store.dispatch("API_index/applyEnroll", {
            user_id: this.USER_INFO.id,
            source: this.PJSource,
            event_id: this.$route.query.id,
            contacts: this.form.name,
            contact_information: this.form.concatMethed,
            company: this.form.company,
          });
          if (data.success) {
            this.dialogVisible = false;
            this.status = 1;
            this.$message({
              message: "提交成功",
              type: "success",
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async getDetail() {
      let data = await this.$store.dispatch("API_index/getStaticContent", {
        id: this.$route.query.id,
      });
      if (data.success) {
        this.detail = data.data;
        console.log(this.detail);
      }
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .el-dialog__footer {
  padding-top: 0;
}
.detailBox {
  padding-bottom: 20px;
}
.title {
  font-size: 21px;
  font-weight: 900;
  line-height: 50px;
  text-align: center;
}
.date {
  text-align: center;
  color: #909399;
  padding-bottom: 20px;
}
.applyBtn {
  text-align: center;
  line-height: 80px;
  span {
    padding-left: 5px;
    color: #027fff;
    cursor: pointer;
  }
}
.demo-input-suffix {
  padding-left: 20px;
  display: flex;
  white-space: nowrap;

  align-items: center;
  .input {
    width: 220px;
  }
}
.form {
  display: flex;
  padding: 0 50px;
  border-bottom: 1px solid #f2f2f2;
  img {
    width: 214px;
    height: 162px;
  }
  .forms {
    width: 400px;
    padding-left: 15px;
  }
}
.confirmBtn {
  background: #ff5155;
  border: none;
}
.openTitle {
  font-weight: bold;
}
.open {
  margin: 20px 0 0;
  i {
    color: #ff5155;
    margin-left: 5px;
    border: 1px solid #ff5155;
    border-radius: 50%;
  }
}
</style>
